﻿﻿<template>
  <section class="relative">
    <button
      type="button"
      class="btn tertiary brand back-button"
      @click="sendToPreviousStep"
    >
      <font-awesome-icon
        icon="fa-light fa-arrow-left-long"
        class="icon-size-2 brand"
      />
    </button>
    <header
      class="pb-1"
      :class="isDesktopWidth ? 'mb-3 border-bottom' : 'mb-1'"
    >
      <h1 class="brand text-center">
        <i18n-t keypath="forgotPassword.title">
          <template #sr>
            <span class="sr-only">{{ t('forgotPassword.titleSr') }}</span>
          </template>
        </i18n-t>
      </h1>
    </header>
  </section>
  <!-- choose password reset method -->
  <section class="stack size-0" v-if="state.showChooseResetMethod">
    <p class="mb-2">
      {{ t('forgotPassword.description') }}
    </p>
    <div class="stack size-1">
      <button
        type="button"
        class="btn primary forgot-pass-cta"
        @click="chooseEmail"
      >
        {{ t('forgotPassword.email.label') }}
      </button>
      <button
        type="button"
        class="btn primary forgot-pass-cta"
        @click="choosePhone"
      >
        {{ t('forgotPassword.mobile.label') }}
      </button>
    </div>
  </section>
  <!-- reset methods -->
  <section>
    <!-- send email -->
    <form
      @submit.prevent="recaptchaVerify('email')"
      v-if="state.showEnterEmailForm"
      class="stack size-3"
    >
      <text-input
        id="EmailInput"
        name="EmailInput"
        v-model="state.email"
        type="email"
        title="Email"
        :label="t('forgotPassword.email.label')"
        placeholder="Email"
        aria-describedby="NameTip"
        :class="{ invalid: !meta.valid, valid: meta.valid }"
      />
      <button
        type="submit"
        class="btn primary"
        :disabled="
          !meta.dirty || !meta.valid || isSubmitting || state.submitting
        "
      >
        <Loading :loading="state.submitting" />
        {{ t('forgotPassword.email.submitCta') }}
      </button>
      <div class="reset-instructions">
        <p class="mb-0">{{ t('forgotPassword.email.forgotEmailInstructions.label') }}:</p>
        <ol>
          <li>
            {{ t('forgotPassword.email.forgotEmailInstructions.stepOne') }}
          </li>
          <li>
            {{ t('forgotPassword.email.forgotEmailInstructions.stepTwo') }}
          </li>
        </ol>
      </div>
    </form>
    <!-- send text -->
    <form
      @submit.prevent="recaptchaVerify('phone')"
      v-if="state.showEnterPhoneNumberForm"
      class="stack size-3"
    >
      <text-input
        id="mobilePhoneInput"
        v-model="state.mobilePhoneDisplay"
        type="tel"
        name="mobilePhoneInput"
        title="Mobile Phone Number"
        aria-describedby="NumberTip"
        :label="t('forgotPassword.mobile.label')"
        class="w-100 flex-column"
        :required="true"
        :focus="false"
        @full-number="state.mobilePhone = $event"
      />
      <button
        type="button"
        class="btn primary"
        :disabled="!meta.valid || state.submitting"
        @click="recaptchaVerify('phone')"
      >
        <Loading :loading="state.submitting" />
        {{ t('forgotPassword.mobile.sendCodeCta') }}
      </button>
    </form>
    <!-- verify phone reset code -->
    <form
      class="stack size-3 phone-verify-code"
      v-if="state.showVerifyCodeStep"
      @submit.prevent="verifyPasswordCode"
    >
      <div :class="{ disabled: isSubmitting || state.submitting }">
        <p class="line-height-2">
          <i18n-t keypath="accountSettings.generalInfo.phoneNumber.modal.description">
            <template #phone>
              <strong>{{ state.mobilePhone }}</strong>
            </template>
          </i18n-t >
        </p>
        <p class="line-height-2 font-size-1 mt-0">
          <i18n-t keypath="accountSettings.generalInfo.mfa.modal.helpText">
            <a
              href="https://support.talkingparents.com/hc/en-us"
              target="_self"
              class="font-size-1 brand underline text-center mt-3"
            >
              {{ t('accountSettings.generalInfo.mfa.modal.helpCenter') }}
            </a>
          </i18n-t>
        </p>
      </div>
      <div class="place-center">
        <code-input
          @codeResult="handleCodeResult"
          :disabled="isSubmitting || state.submitting"
          :class="{ disabled: isSubmitting || state.submitting }"
          :error-state-override="verifyResetCodeError.length > 0"
          required
        />
      </div>
      <span
        v-if="verifyResetCodeError.length > 0"
        class="font-size-0 error"
        style="margin-top: 0.5rem"
      >
        {{ verifyResetCodeError }}
      </span>
      <button
        type="submit"
        class="btn primary"
        :disabled="
          !meta.dirty || !meta.valid || isSubmitting || state.submitting
        "
      >
        <Loading :loading="state.submitting" />
        Verify
      </button>
      <button
        class="btn tertiary underline brand"
        type="button"
        @click="resendCode()"
        style="font-weight: var(--font-weight-1)"
        :class="{ disabled: isSubmitting || state.submitting }"
        :disabled="isSubmitting || state.submitting"
      >
        {{ t('accountSettings.generalInfo.phoneNumber.modal.sendAnotherCode') }}
      </button>
      <span
        v-if="state.showResendSuccess"
        class="bg-lt-blue py-0 font-weight-2 font-size-0 radius-3 mt-2 text-center"
      >
        {{ t('forgotPassword.verifyCode.resentSuccess') }}
      </span>
    </form>
  </section>
  <!-- help center link -->
  <div v-if="!state.showVerifyCodeStep" class="place-center">
    <a
      href="https://support.talkingparents.com/hc/en-us"
      target="_self"
      class="brand underline text-center mt-3"
      :class="isDesktopWidth ? 'font-size-1 ' : 'font-size-0'"
    >
      {{ t('forgotPassword.helpCenterCta') }}
    </a>
  </div>
  <google-recaptcha
    ref="googleRecaptcha"
    :verified-callback="submit"
    @loaded="onRecaptchaLoaded"
  />
</template>

<script lang="ts" setup>
import ErrorHelper from '@/exports/error'
import GoogleRecaptcha from '@/components/GoogleRecaptchaComponent.vue'
import { useLoginStore } from '@/stores/LoginStore'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import TextInput from '@/components/library/TextInput.vue'
import { useForm } from 'vee-validate'
import { storeToRefs } from 'pinia'
import CodeInput from '@/components/CodeInputComponent.vue'
import Loading from '@/components/library/Loading.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { useI18n, I18nT } from 'vue-i18n'
import { useModals } from '@/composables/useModal/useModal'

const { t } = useI18n({ useScope: 'global' })

const loginStore = useLoginStore()
const { verifyResetCodeError } = storeToRefs(loginStore)
const { requestPasswordReset, verifyResetCode, setVerifyErrorMessage } =
  loginStore

const commonStore = useCommonStore()
const { isDesktopWidth } = commonStore

const router = useRouter()

const googleRecaptcha = ref(GoogleRecaptcha)

const formValidationSchema = {
  EmailInput: 'email',
  mobilePhoneInput: 'phone',
  VerifyCodeInput: () => {
    if (state.showVerifyCodeStep == true) {
      if (state.verifyCode.length === 6) return true
      return t('forgotPassword.verifyCode.lengthError')
    } else {
      return true
    }
  }
}

const { handleSubmit, meta, handleReset, isSubmitting, setErrors } = useForm({
  validationSchema: formValidationSchema
})

const state = reactive({
  email: '',
  mobilePhone: '',
  mobilePhoneDisplay: '',
  loading: false,
  verifyCode: '',
  showChooseResetMethod: true,
  showEnterPhoneNumberForm: false,
  showEnterEmailForm: false,
  showVerifyCodeStep: false,
  sendType: '',
  recaptchaLoaded: false,
  showResendSuccess: false,
  showVerifyCodeModal: false,
  resendCode: false,
  submitting: false
})

function onRecaptchaLoaded() {
  state.recaptchaLoaded = true
}

function sendToPreviousStep() {
  if (state.showVerifyCodeStep) {
    state.showEnterPhoneNumberForm = true
    state.showVerifyCodeStep = false
  } else if (state.showEnterEmailForm || state.showEnterPhoneNumberForm) {
    state.showChooseResetMethod = true
    state.showEnterEmailForm = false
    state.showEnterPhoneNumberForm = false
  } else {
    router.push({ name: 'login' })
  }
}

async function recaptchaVerify(sendType: string) {
  state.submitting = true
  state.sendType = sendType
  onSubmit()
}
const onSubmit = handleSubmit(() => {
  submit()
})

function resendCode() {
  recaptchaVerify('phone')
  state.resendCode = true
  submit()
}

async function submit(token?: string) {
  if (!token) {
    //GoogleRecaptcha.verify()
    const response = googleRecaptcha.value?.verify()
    // console.log('response' , response)
    return
  }
  // if sending email address
  if (state.sendType == 'email') {
    sendEmail(token)
  }
  // if sending phone number
  if (state.sendType == 'phone') {
    submitPhoneNumber(token)
  }
}

const { generateModal } = useModals()

function toggleEmailModal() {
  generateModal({
    default: {
      headerText:  t('forgotPassword.emailSentModal.title'),
      contentText: t('forgotPassword.emailSentModal.description'),
      footerButtonLabel: t('forgotPassword.emailSentModal.okayCta')
    },
    callback: {
      closeFn: () => state.submitting = false
    },
    config: {
      closeOnConfirm: true,
    },
  })
}

async function sendEmail(token: string) {
  try {
    const payload = {
      email: state.email.trim(),
      recaptchaToken: token
    }
    await requestPasswordReset(payload)
    toggleEmailModal()
    handleReset()
  } catch (e) {
    ErrorHelper.handleError(e, 'sendEmail')
    return
  }
}

async function submitPhoneNumber(token: string) {
  try {
    const payload = {
      mobilePhone: state.mobilePhone,
      recaptchaToken: token
    }
    await requestPasswordReset(payload)
    // check store if there was an rate limit error
    if (verifyResetCodeError.value.length > 0) {
      setErrors({ mobilePhoneInput: verifyResetCodeError.value })
      state.submitting = false
    } else {
      state.showChooseResetMethod = false
      state.showEnterPhoneNumberForm = false
      state.showVerifyCodeStep = true
      state.submitting = false

      handleReset()
      if (state.resendCode) {
        state.showResendSuccess = true
        state.resendCode = false
      }
    }
  } catch (e) {
    ErrorHelper.handleError(e, 'submitPhoneNumber')
    return
  }
}

function verifyPasswordCode() {
  try {
    state.submitting = true
    state.showResendSuccess = false
    const payload = {
      verifyCode: state.verifyCode,
      mobilePhone: state.mobilePhone
    }
    verifyResetCode(payload).then((code) => {
      if (code) {
        // Redirect to reset password page
        router.push({
          name: 'resetPassword',
          query: { c: code }
        })
      } else {
        if (verifyResetCodeError.value.length > 0) {
          state.showVerifyCodeModal = true
        }
        state.submitting = false
      }
    })
  } catch (e) {
    ErrorHelper.handleError(e, 'verifyPasswordCode', true)
    return
  }
}

function resetVerifyErrorModal() {
  setVerifyErrorMessage('')
}

function handleCodeResult(value: string) {
  resetVerifyErrorModal()
  state.showResendSuccess = false
  state.verifyCode = value
}

function chooseEmail() {
  state.showEnterEmailForm = true
  state.showChooseResetMethod = false
}

function choosePhone() {
  state.showEnterPhoneNumberForm = true
  state.showChooseResetMethod = false
}
</script>

<style lang="scss" scoped>
p {
  line-height: var(--lineheight-2);
}
.phone-verify-code {
  p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.reset-instructions {
  line-height: var(--lineheight-2);
  p {
    margin-top: 0;
  }
  ol {
    color: var(--gray-5);
    font-size: var(--font-size-0);
    list-style-type: num;
    line-height: var(--lineheight-2);
    padding-left: 1.75rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  li {
    padding-left: 0.5rem;
  }
  li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

:deep(.iti) {
  width: 100%;
}

.disabled {
  opacity: 0.5;
}

.forgot-pass-cta {
  width: 244px;
}
.back-button {
  position: absolute;
  bottom: 6rem;
  @media (min-width: 48em) {
    bottom: calc(100%);
    right: calc(100% - 0.5rem);
  }
}
</style>
