<template>
  <div class="calling-modal-meta">
    <div
      v-for="[errorKey, active] in callingErrors"
      :class="{ warn: active }"
      v-skeleton="loading"
      :key="errorKey"
    >
      <font-awesome-icon
        v-if="errorKey != 'noMinutes'"
        icon="fa-light fa-circle-exclamation"
        class="icon-size-2"
        style="margin-top: 2px"
      />
      <div>
        <!-- disabled errors: the cta is in the text -->
        <!-- video: active only when disabled is not the primary cta -->
        <Translation
          v-if="te(`calling.preCheck.${errorKey}Text`)"
          :keypath="`calling.preCheck.${errorKey}Text`"
          scope="global"
        >
          <span
            v-if="
              isVideoCall &&
              primaryCta === errorKey &&
              te(`calling.preCheck.${errorKey}Cta`)
            "
          >
            {{ t(`calling.preCheck.${errorKey}Cta`) }}
          </span>
          <button
            v-else-if="te(`calling.preCheck.${errorKey}Cta`)"
            @click="goToSettings"
            class="btn tertiary"
            type="button"
          >
            <strong>{{ t(`calling.preCheck.${errorKey}Cta`) }}</strong>
          </button>
        </Translation>
        <!-- phone: upgrade cta is in the error -->
        <button
          v-if="
            !isVideoCall && errorKey == constants.CAN_CALL_STATUS.notPremium
          "
          @click="goToPaywall"
          class="btn tertiary w-100"
          type="button"
        >
          <strong>{{ t(`calling.preCheck.${errorKey}Cta`) }}</strong>
          <font-awesome-icon
            icon="fa-light fa-arrow-right-long"
            class="icon-size-2 brand"
          />
        </button>
      </div>
    </div>
    <slot name="before-minutes" />
    <div
      class="error text-center flex-column align-middle mt-000 gap-1"
      v-if="noMinutes"
      v-skeleton="{
        loading: loading,
        minWidth: 20,
        maxWidth: 20
      }"
    >
      {{ t(`calling.preCheck.noMinutesText`) }}
      <button
        v-if="
          !isVideoCall ||
          (isVideoCall && primaryCta != constants.CAN_CALL_STATUS.noMinutes)
        "
        type="button"
        class="btn-text font-medium"
        @click="addMinutesClick"
      >
        + {{ t('calling.minutes.addMinutes') }}
      </button>
    </div>
    <div v-else-if="!loading" class="available-minutes">
      <p
        v-skeleton="{
          loading: loading,
          minWidth: 20,
          maxWidth: 20
        }"
      >
        {{ t('calling.minutes.availableMinutes') }}:
        <span class="font-bold">{{
          t('calling.minutes.balance', { amt: callingBalance })
        }}</span>
      </p>
      <!-- video call does not include add minutes cta -->
      <button
        v-if="!isVideoCall"
        type="button"
        class="btn-text font-bold"
        @click="addMinutesClick"
        v-skeleton="{
          loading: loading,
          minWidth: 20,
          maxWidth: 20
        }"
      >
        + {{ t('calling.minutes.addMinutes') }}
      </button>
    </div>
    <slot name="after-minutes" />
    <Teleport to=".video.dialog-footer" :disabled="!isVideoCall">
      <button
        v-if="isVideoCall && !loading"
        class="btn font-medium mt-000 mb-1"
        :class="primaryCta === 'cancel' ? 'secondary' : 'primary'"
        type="button"
        @click="pickCta"
      >
        {{ t(`calling.preCheck.${primaryCta}Cta`) }}
      </button>
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n, Translation } from 'vue-i18n'
import { useCallingStore } from '@/stores/CallingStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { storeToRefs } from 'pinia'
import { useModals } from '@/composables/useModal/useModal'
import UpgradeMinutes from '@/components/UpgradeMinutes.vue'
import { br } from '@/plugins/trackerPlugin'
import constants from '@/exports/constants'

const { t, te } = useI18n({ useScope: 'global' })
const track: any = inject('$trackingTrack')
const router = useRouter()
const route = useRoute()

const callingStore = useCallingStore()
const { canCallStatus } = storeToRefs(callingStore)
const {
  setShowVideoCall,
  setShowAudioCall,
  setShowCallingSetup,
  setShowVideoCallError
} = callingStore

const accountSettingsStore = useAccountSettingsStore()
const { callingBalance, subscriptionT0 } = storeToRefs(accountSettingsStore)
const { setPaywallPreviousPage, manageSubscription } = accountSettingsStore

const { createSlot, generateModal, HTMLtoComponent } = useModals()

const props = withDefaults(
  defineProps<{
    loading: boolean
    isVideoCall?: boolean
  }>(),
  {
    loading: false,
    isVideoCall: true
  }
)

const errorType: Map<string, string> = new Map([
  [constants.CAN_CALL_STATUS.videoAllowed, 'video'],
  [constants.CAN_CALL_STATUS.phoneAllowed, 'phone'],
  [constants.CAN_CALL_STATUS.coParentPhoneDisabled, 'phone'],
  [constants.CAN_CALL_STATUS.coParentVideoDisabled, 'video'],
  [constants.CAN_CALL_STATUS.noMinutes, 'both'],
  [constants.CAN_CALL_STATUS.notPremium, 'both'],
  [constants.CAN_CALL_STATUS.phoneCallingDisabled, 'phone'],
  [constants.CAN_CALL_STATUS.videoCallingDisabled, 'video'],
  [constants.CAN_CALL_STATUS.coParentTierFree, 'video']
])

function pickCta() {
  switch (primaryCta.value) {
    case constants.CAN_CALL_STATUS.notPremium:
      goToPaywall()
      break
    case constants.CAN_CALL_STATUS.phoneCallingDisabled:
    case constants.CAN_CALL_STATUS.videoCallingDisabled:
      goToSettings()
      break
    case constants.CAN_CALL_STATUS.noMinutes:
      addMinutesClick()
      break
    default:
      setShowVideoCallError(false)
  }
}

function addMinutesClick() {
  if (canCallStatus.value.get(constants.CAN_CALL_STATUS.notPremium)) {
    showUpgradeModal()
  } else {
    showCallingMinutesModal()
  }
}

function showCallingMinutesModal() {
  generateModal({
    slot: {
      content: createSlot('content', UpgradeMinutes).content
    },
    default: {
      headerText: t('calling.minutes.modalTitle')
    },
    config: {
      showHeader: true,
      showFooter: false,
      showCloseButton: true,
      closeOnOutsideClick: true
    }
  })
}

function showUpgradeModal() {
  const upgradeText = `<p class="text-center font-size-1">${t('calling.minutes.unableToAddText')}</p>`
  generateModal({
    slot: createSlot('content', HTMLtoComponent(upgradeText)),
    default: {
      headerText: t('calling.minutes.unableToAdd'),
      contentText: upgradeText,
      footerButtonLabel: t('calling.preCheck.notPremiumCta')
    },
    callback: {
      confirmFn: goToPaywall
    },
    config: {
      showHeader: true,
      showFooter: true
    }
  })
}

function goToPaywall() {
  track(br.eventTypes.appAction, {
    feature: br.appActionFeature.calling,
    name: br.appActionEventNames.CTAUpgrade
  })

  setShowAudioCall(false)
  setShowVideoCall(false)
  setShowVideoCallError(false)
  
  if (subscriptionT0.value) {
    setPaywallPreviousPage({ name: route.name?.toString(), params: route.params })
    router.push({ name: 'accountTypeStep1' })
  } else {
    manageSubscription(route.fullPath.substring(1))
  }
}

function goToSettings() {
  setShowAudioCall(false)
  setShowVideoCall(false)
  setShowVideoCallError(false)
  if (subscriptionT0.value || !inCallingFeature.value) {
    router.push({ name: 'accountableCalling' })
  } else {
    setShowCallingSetup(true)
  }
}

const noMinutes = computed(() =>
  canCallStatus.value.get(constants.CAN_CALL_STATUS.noMinutes)
)

// get respective active errors
// does not include add minutes error because it looks different and is in a different place
const callingErrors = computed(() =>
  [...canCallStatus.value].filter(
    ([key, active]) =>
      active &&

      (key != constants.CAN_CALL_STATUS.noMinutes) &&
      (key != constants.CAN_CALL_STATUS.phoneAllowed) &&
      ((props.isVideoCall ? errorType.get(key) === 'video' : errorType.get(key) === 'phone') ||
      (errorType.get(key) === 'both'))

  )
)

// no room for more than one primary cta when there are multiple errors
// the cta with the most priority: upgrade > disabled > add minutes
const primaryCta = computed(() =>
  canCallStatus.value.get(constants.CAN_CALL_STATUS.notPremium)
    ? constants.CAN_CALL_STATUS.notPremium
    : props.isVideoCall
      ? canCallStatus.value.get(constants.CAN_CALL_STATUS.videoCallingDisabled)
        ? constants.CAN_CALL_STATUS.videoCallingDisabled
        : canCallStatus.value.get(constants.CAN_CALL_STATUS.noMinutes)
          ? constants.CAN_CALL_STATUS.noMinutes
          : 'cancel'
      : canCallStatus.value.get(constants.CAN_CALL_STATUS.phoneCallingDisabled)
        ? constants.CAN_CALL_STATUS.phoneCallingDisabled
        : canCallStatus.value.get(constants.CAN_CALL_STATUS.noMinutes)
          ? constants.CAN_CALL_STATUS.noMinutes
          : 'cancel'
)

const inCallingFeature = computed(() => route.meta.trackingName === 'calling')
</script>
<style scoped>
.calling-modal-meta {
  display: flex;
  flex-direction: column;
  gap: var(--size-00);
  justify-content: center;
  top: 0;
  z-index: 2500;
  background-color: var(--surface-1);
  max-width: 45ch;
  margin-inline: auto;
  inset-inline: 0;
  border-radius: 1rem;
  width: fit-content;
  padding-inline: 1rem;
}
.calling-modal-meta > * {
  margin: 0;
}
.calling-modal-meta > * + * {
  margin-top: 1em;
}

@media (min-width: 48em) {
  .calling-modal-meta {
    padding-inline: 0;
  }
}

.available-minutes {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 0.25rem;
  width: fit-content;
}
.btn-text {
  white-space: none;
  width: fit-content;
  background-color: transparent;
  border-radius: 2.625rem;
  padding: 0;
  color: var(--text-1);
  text-decoration: none;
  color: var(--brand);
  box-shadow: none !important;
  padding: 0;
  border: none;
}

.warn {
  width: fit-content;
  background-color: var(--surface-warning);
  border-radius: var(--radius-2);
  padding: var(--space-3xs) var(--space-xs);
  margin: 0 auto;
  color: var(--gray-12);

  display: flex;
  align-items: start;
  gap: 0.5rem;
  font-variation-settings: 'wght' 500;
  strong {
    --base-weight: 500;
  }
}

svg {
  flex: none;
}

strong {
  color: var(--brand-10);
}
</style>
